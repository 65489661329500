
.jumbo-input {
  display: flex;
  font-size: 12vmin;
  padding: 0.5em 0;

  input {
    display: block;
    background: none;
    text-align: right;
    border: 0px;
    padding: 0;
    margin: 0;
    flex: 1;
  }

  label {
    display: inline-block;
  }

  button {
    margin: 0;
    padding: 0;
    text-align: right;
    width: auto;
  }
  // width: 100%;
}

.item-list {
  margin: 0;
  padding: 24px 0;

  .scroll;

  li {
    list-style-type: none;
    line-height: 48px;
  }

  button {
    margin: 0;
    padding: 0;
    text-align: right;
    width: auto;
  }
}


.transaction-editor {
  .row;
  flex: 1;

  .col {
    border-left: 1px solid #cdcdcd;

    & > * {
      border-bottom: 1px solid #cdcdcd;
      padding-left: 24px;
      padding-right: 24px;
    }

    keypad {
      padding: 0;
    }
  }
}

.transaction-summary {
  line-height: 48px;
  background: @tom-background-blue;
  border-bottom: 1px solid @tom-blue;
  text-align: center;
  cursor: pointer;
}
