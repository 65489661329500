.report-box {
  display: flex;
  display: -webkit-flex;
  flex-direction: column;
  border: 1px solid @tom-gray;
  width: 100%;
  max-width: 600px;
  padding: 0.5em 0.5em;
  margin: 0em 0em 0.5em 0.0em;
  height: auto;
  list-style: none;
}

.report-box-last {
  .report-box;
  margin: 0em 0em 0em 0em;
}

.report-item {
  width: 100%;
  text-align: center;
}

.report-btn {
  width: 150px;
}

.report-date-btn {
  padding-left: 0.5em;
  padding-right: 0.5em;
}

.report-date-container {
  display: flex;
  display: -webkit-flex;
  flex-direction: row;
  align-items:center;
  justify-content:space-around;
}

.report-term-container-row {
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
}

.report-term-container-col {
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
}

.report-table {
  width: 100%;
  text-align: left;
}

.report-table th {
  font-weight: bold;
}

.report-table td,th {
  padding: 0.3em 0.3em 0.3em 0.3em;
}

.report-table .right {
  text-align: right;
}

.report-table .line th {
  border-top: 1px solid black;
}

.input-time-row {
  .row;
  justify-content: space-between;
}

.input-time-dialog {
  top: 50%;
  transform: translateY(-50%);
}

@media all and (max-width: 375px) {
  .report-item {
    font-size: 0.8em;
  }
  .input-time-dialog {
    font-size: 0.8em;
  }
  .input-time-row button {
    min-height: 30px;
    min-width: 30px;
  }
  .report-btn {
    min-height: 30px;
  }
  .report-date-btn {
    min-height: 30px;
  }
}

@media all and (max-width: 414px) {
  .report-date-container {
    flex-direction: column;
  }
  .report-term-container-row {
    display: none;
  }
  .report-term-container-col {
    display: flex;
  }
}

@media all and (min-width: 415px) {
  .report-term-container-row {
    display: flex;
  }
  .report-term-container-col {
    display: none;
  }
}
