dialog {
  position: fixed;
  top: 10vh;
  left: 50vw;
  margin-left: -280px;
  border: none;
  border-radius: 10px;
  box-sizing: content-box;
  width: 400px;
  padding: 20px 80px;
  background: white;
  z-index: 10002;
  display: none;


  &[open] {
    display: block;
  }
  .actions {
    display: flex;
    flex-direction: row-reverse;

    button {
      flex: 1;
      margin-right: 10px;
    }

    button:first-of-type {
      margin-right: 0;
    }
  }

  .message {
    display: block;
    margin-bottom: 20px;
    text-align: center;
  }
}

::backdrop {
  background: rgba(0, 0, 0, 0.4);
}

.backdrop {
  background: rgba(0, 0, 0, 0.4);
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 10000;
}

// customer in dialog
dialog {
  .message {
    line-height: 1.6;
    color: grey;
  }

  .customer {
    margin:0;
    font-size: 1.2em;
    color: black;

    .number {
      line-height: 1;
      display: inline-block;
      vertical-align: text-bottom;
      font-size: 0.8em;
      padding: 0.2em 0.4em;
      background: @tom-blue;
      color: #FFF;
    }

    .number:before {
      content: '#';
    }
  }

  .amount {
    font-size: 3em;
    color: black;
  }
}
