.menu {
  position: fixed;
  width: 300px;
  left: 0;
  top: 0;
  height: 100vh;
  overflow-y: scroll;
  box-shadow: 1px 1px 3px 0px rgba(100, 100, 100, 0.75);
  background: white;
  z-index: 10;

  ul {
    padding: 0;
    margin: 0;
    border-top: 1px solid #808080;

    // because the dragable is in the way on desktop :(
    margin-top: 48px;
  }

  li {
    line-height: 48px;
    border-bottom: 1px solid #808080;
    list-style-type: none;
  }

  li a {
    color: #000;
    text-decoration: none;
    width: 100%;
    padding: 0 12px;
    display: inline-block;
  }

  transition: transform 300ms;
  transform: translateX(-320px);

  &.menu-open {
    transform: translateX(0px);
  }
}

#main-view {
  position: relative;
}
