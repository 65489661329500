.dropdown-menu {
  box-sizing: content-box;
  .card;
  position: absolute;
  display: none;
  z-index: 101;

  right: 0;

 padding: 0;
  margin: 0;
  list-style-type: none;

  &.active {
    display: block;
  }

  li {
    border-bottom: 1px solid @tom-light-gray;

    &:last-child {
      border-bottom: none;
    }
  }


  a, button {
    min-width: 48px;
    line-height: 48px;

    display: block;
    background: none;
    border: none;
    margin: 0;
    padding: 0 10px;
    text-align: left;
    align-items: flex-start;
    color: @tom-text-primary;
    text-decoration: none;

    &:hover {
      background: @tom-background;
    }

    &:disabled {
      color: @tom-light-gray;

      &:hover {
        background: none;
      }
    }
  }

}

.dropdown-wrapper {
  position: relative;
  box-sizing: content-box;
}
