.transactions-table-wrapper {
  margin: 12px;
}

.transactions-table-row {
  background: white;
  box-shadow: 1px 1px 3px 0px rgba(0,0,0,0.75);

  &.selected {
    background: lightblue;
  }
}

.transactions-table-control-cell {
  width: 20px;
  padding-right: 12px;
  padding-left: 12px;
}

.transactions-table {
  border-collapse: separate;
  border-spacing: 0px 12px;
  white-space: nowrap;
  text-align: center;
  width:100%;

  .state {
    text-transform: capitalize;
  }

  // state styles
  .canceled {
    color: @tom-light-gray;
  }

  .complete {

  }

  .refunded {
    .canceled;

    .state {
      .text-danger;
    }
  }

  .authorized {
    .bg-warning;
  }

  .rejected {
    .canceled;

    .state {
      .text-danger;
    }
  }
}
