.alerts {
    position: fixed;
    top: 48px;
    width: 100%;
}

.alert {
    flex-direction: row;
    font-size: 24px;
    border: 1px solid;
    padding: 0 24px;
}

.alert button {
  background: none;
  display: inline-block;
  margin: 0;
  padding: 0;
  line-height: 48px;
  width: auto;
  font-size: inherit;
  color: inherit;
}

.alert .message {
  flex: 1;
  font-weight: 300;
  font-size: 18px;
  line-height: 1.5;
  padding: 10px 0;
}

.alert-danger {
  color: @tom-danger-red;
  border-color: @tom-danger-red;
  background: lighten(@tom-danger-red, 25%);
}

.alert-warning {
  color: darken(@tom-warning-yellow, 10%);
  border-color: @tom-warning-yellow;
  background: lighten(@tom-warning-yellow, 35%);
}

.alert-success {
  color: @tom-success-green;
  border-color: @tom-success-green;
  background: lighten(@tom-success-green, 55%);
}
