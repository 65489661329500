@import "colors";
@import "card";
@import "customer-card";
@import "dropdown-menu";
@import "keypad";
@import "menu";
@import "titlebar";
@import "dialog";
@import "transaction-entry";
@import "transactions-table";
@import "alert";
@import "report";

:root {
  font-family: sans-serif;
  font-size: 24px;
  background: @tom-background;
  color: @tom-text-primary;
}

// Prevent pull to refresh
body {
  overflow: hidden;
}

// Fixes firefox flexbox issue
* {
  min-width: 0;
}

*, *:before, *:after {
  box-sizing: border-box;
}

.row {
  display: flex;
  flex-direction: row;
}

.col {
  display: flex;
  flex-direction: column;
}

.scroll {
  overflow-y: scroll;

  &.flex {
    height: 0;
  }
}

.flex,
.flex-1 { flex: 1; }
.flex-2 { flex: 2; }
.flex-3 { flex: 3; }

.pull-right {
  float: right;
}

.text-success {
  color: @tom-success-green;
}

.text-danger {
  color: @tom-danger-red;
}

.text-gray {
  color: @tom-gray;
}

.text-code {
  font-family: monospace;
}

.bg-warning {
  background: lighten(@tom-warning-yellow, 25%);
}

.center-content {
  flex-direction: column;
  display: flex;
  align-items: center;
  justify-content: center;
}

input {
  font-size: 1em;
  padding: 0.5em;
  border-radius: 3px;
  border: 1px solid grey;
  width: 100%;
  margin: 0.5em 0;
  display: block;

  &.error {
    border-color: @tom-danger-red;
  }
}

button {
  color: white;
  background: @button-default;
  font-size: 1em;
  padding: 0.2em;
  border-radius: 3px;
  border: 0px;
  width: 100%;
  margin: 0.5em 0;
  display: block;
  min-height: 48px;
  min-width: 48px;

  &.primary {
    background: @button-primary;
  }

  &.minor {
    background: none;
    border-radius: 24px;
    color: inherit;
  }
}

.actions {
  button {
    margin: 0.5em 0;
  }
}

div {
  display: flex;
  flex-direction: column;
}

#main-view {
  height: 100vh;
  width: 100vw;
}

.main {
  background: @tom-background;
  flex: 1;
  overflow-y: scroll;

  &.flex {
    height: 0;
  }
}

@media (min-width: 600px) and (min-height: 500px) {
  .for-small-layout {
    display: none;
  }
}

@media (max-width: 600px) {
  keypad {
    display: none;
  }

  dialog {
    top: 0;
    left: 0;
    padding: 16px;
    margin: 16px;
    width: auto;


    .actions {
      flex-direction: column;

      button {
        margin: 0 0 12px 0;
      }
    }
  }

  .transaction-editor {
    flex-direction: column;
    min-height: 470px;
  }

  .col > * {
    padding-left: 16px;
    padding-right: 16px;

  }

  .flex-2 {
    flex: none;
  }
}

@media (max-height: 500px) {
  keypad {
    display: none;
  }
}
