
titlebar, .titlebar {
  -webkit-user-select: none;
  -webkit-app-region: drag;
  width: 100%;
  height: 48px;
  color: white;
  background: @tom-blue;
  display: flex;
  flex-direction: row;

  line-height: 48px;

  .center {
    .flex;
    display: flex;
    flex-direction: row;
  }

  button, a {
    display: inline;
    background: none;
    margin: 0;
    padding: 0;
    width: auto;
  }

  h1 {
    line-height: 48px;
    display: inline;
    font-weight: 300;
    font-size: 24px;
    margin: 0;
  }

  .terminal-title {
    margin-right: 10px;
  }
}
