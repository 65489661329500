keypad {
  width: 100%;
  display: flex;
  flex-direction: column;
  font-size: 42px;

  .row {
    flex: 1;
    min-height: 1.6em;
  }

  button {
    margin: 0;
    color: #414448;
    font-weight: 300;
    outline: 0;
    display: block;
    flex: 1;

    font-size: 100%;
    padding: 0.2em 0;
    background: #f3f6f9;
    border: #e3e8ed solid 1px;
    border-radius: 0;
    border-right: 0;
    border-bottom: 0;
  }

  button:first-child {
    border-left: 0px;
  }

  .row:first-child button {
    border-top: 0px;
  }

  .small-text {
    display: block;
    flex: 1;

    font-size: 60%;
  }

  button:active {
    background: #e3e8ed;
    z-index: 100;
  }
}
