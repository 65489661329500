@card-width: 220px;
@card-margin: 8px;

customer-list {
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

customer-card {
  .card;
  width: @card-width;
  margin: @card-margin;

  .names{
    padding: 6px;
  }

  & > .row {
    border-top: 1px solid lightgrey;

    &:first-child {
      border: none;
    }
  }

  h1, h2 {
    margin: 0;
    text-align: right;
  }

  h1 {
    margin-bottom: 5px;
    font-weight: normal;
    font-size: 0.9rem;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  h2 {
    font-size: 1.2rem;
  }


  dl {
    margin: 0;
    padding: 6px;
    flex-wrap: wrap;
  }

  dt {
    width: 30%;
  }

  dd {
    margin: 0;
    text-align: right;
    width: 70%;
  }

  dt, dd {
    font-size: 0.5rem;
    margin-bottom: 4px;

    &:last-of-type {
      margin-bottom: 0;
    }
  }
}

@padded-card: @card-width + 2 * @card-margin;

@media (max-width: (2 * @padded-card - 1)) {
  customer-card {
    margin: 0.2em;
    // box-shadow: none;
    // border-bottom: 1px solid black;
    width: 100%;
  }
}

// These media queries are shit, if you can think of a better way, do it!
.media-grid(@cols, @i: 2) when (@i =< @cols) {
  @media (min-width: (@i * @padded-card)) {
    customer-list {
      width: @i * @padded-card;
    }
  }
  .media-grid(@cols, (@i + 1));
}

.media-grid(7);
